export default {
	properties: [
		{
			key: 'nota',
			type: 'textarea',
			value: '',
			is_title: true,
		},
	],
	singular_model_name_spanish: 'Nota importante',
	plural_model_name_spanish: 'Notas importantes',
	create_model_name_spanish: 'Nueva',
	text_delete: 'la',
}