export default [
    'amortizacion',
    'siniestro',
    'color_siniestro',
    'aseguradora',
    'asegurado',
    'causa_siniestro',
    'estado_general_siniestro',
    'estado_siniestro',
    // 'localidad',
    'provincia',
    'employee',
    'tipo_orden_de_servicio',
    'gestor_scrap_free',
    'gestor_aseguradora',
    'unidad_negocio',
    'causa_bien',
    'estado_bien',
    'linea',
    'sub_linea',
    'tecnico_asegurado',
    'tecnico_scrap_free',
    'poliza',
    'cobertura',
    'tipo_producto_de_seguro',
    'ramo',
    'transportista',
    'permission',
    'tipo_documento',
    'centro_reparacion',
]