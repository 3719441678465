export default {
	properties: [
		{
			text: 'N°',
			key: 'num',
			type: 'text',
			value: '',
			not_show_on_form: true,
			show: true,
		},
		{
			text: 'Bienes',
			key: 'bienes',
			type: 'search',
			store: 'bien',
			has_many: {
				model_name: 'bien',
				models_from_parent_prop: {
					parent_model_name: 'siniestro',
					models_prop_name: 'bienes',
				}
			},
			show: true,
		},
		{
			text: 'transportista devolucion',
			key: 'transportista_devolucion_id',
			type: 'search',
			store: 'transportista',
			value: '',
			show: true,
		},
		{
			text: 'transportista retiro',
			key: 'transportista_retiro_id',
			type: 'search',
			store: 'transportista',
			value: '',
			show: true,
		},
		{
			text: 'costo flete devolucion',
			key: 'costo_flete_devolucion',
			type: 'number',
			value: '',
			show: true,
		},
		{
			text: 'costo flete ida',
			key: 'costo_flete_ida',
			type: 'number',
			value: '',
			show: true,
		},
		{
			text: 'fecha cobro flete ida',
			key: 'fecha_cobro_flete_ida',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'domicilio devolucion',
			key: 'domicilio_devolucion',
			type: 'textarea',
			value: '',
			show: true,
		},
		{
			text: 'domicilio retiro',
			key: 'domicilio_retiro',
			type: 'textarea',
			value: '',
			show: true,
		},
		{
			text: 'fecha entrega asegurado',
			key: 'fecha_entrega_asegurado',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha entrega scrap free',
			key: 'fecha_entrega_scrap_free',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha estimada entrega asegurado',
			key: 'fecha_estimada_entrega_asegurado',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha estimada entrega scrap free',
			key: 'fecha_estimada_entrega_scrap_free',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha estimada retiro asegurado',
			key: 'fecha_estimada_retiro_asegurado',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha estimada retiro scrap free',
			key: 'fecha_estimada_retiro_scrap_free',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha pedido retiro asegurado',
			key: 'fecha_pedido_retiro_asegurado',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha pedido retiro scrap free',
			key: 'fecha_pedido_retiro_scrap_free',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha retiro asegurado',
			key: 'fecha_retiro_asegurado',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'fecha retiro scrap free',
			key: 'fecha_retiro_scrap_free',
			type: 'date',
			is_date: true,
			value: '',
			show: true,
		},
		{
			text: 'flete otros costos',
			key: 'flete_otros_costos',
			type: 'number',
			value: '',
			show: true,
		},
		{
			text: 'notas transportista devolucion',
			key: 'notas_transportista_devolucion',
			type: 'textarea',
			value: '',
			show: true,
		},
		{
			text: 'notas transportista retiro',
			key: 'notas_transportista_retiro',
			type: 'textarea',
			value: '',
			show: true,
		},
	],
	singular_model_name_spanish: 'Logistica',
	plural_model_name_spanish: 'Logisticas',
	create_model_name_spanish: 'Nueva',
	text_delete: 'la',
}